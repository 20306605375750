<template>
  <form class="filters filters--facility" @submit.prevent="applyFilters">
    <div class="filters__inner">
      <div class="filters__group" v-for="(group, index) in this.list" :key="index">
        <p class="filters__group-name" @click="toggleExpand(index, true)">
          {{ group.name }}
          <span class="filters__chevron"
            :class="{ 'active': hasSelectedItems[index], 'collapse': group.collapse || group.isRolledUp }">
            <img src="@/assets/images/svg/chevron-down.svg" alt="" v-if="!hasSelectedItems[index]">
            <img src="@/assets/images/svg/chevron-down_white.svg" alt="" v-else>
          </span>
        </p>
        <p class="filters__btn" v-if="hasSelectedItems[index] && !group.collapse"
          @click="clearFilter(index, group.name)">
          Очистить</p>
        <ul :class="{ 'expand': group.expand || group.icons, 'collapse': group.collapse }" class="filters__options"
          v-if="group.type === 'checkbox'">
          <li v-for="(option, j) in group.options" :key="j">
            <input type="checkbox" class="input-hidden" :id="`${group.name}-${j}`" v-model="option.selected"
              @click="checkboxHandler(option, group.name, option.id)">
            <label :for="`${group.name}-${j}`" class="label-checkbox">
              <span v-if="group.icons" class="type-icon" :style="`color: ${computeDirectionColor(option.code)}`">
                <svg :data-src="option.image ? option.image.thumbnail_link : option.link" fill="black"></svg>
              </span>
              {{ option.name }}
            </label>
          </li>
        </ul>
        <p class="filters__btn" v-if="group.expand !== undefined && !group.expand && !group.icons && !group.collapse"
          @click="toggleExpand(index)">
          Показать все
          <img src="@/assets/images/svg/chevron-right.svg" alt="">
        </p>
      </div>

      <div class="filters__group" v-if="isAuth">
        <p class="filters__group-name" @click="isExpandCertificate = !isExpandCertificate">
          Сертификация
          <span class="filters__chevron" :class="{ 'active': certificate !== null, 'collapse': !isExpandCertificate }">
            <img src="@/assets/images/svg/chevron-down.svg" alt="" v-if="certificate === null">
            <img src="@/assets/images/svg/chevron-down_white.svg" alt="" v-if="certificate !== null">
          </span>
        </p>
        <ul class="filters__options" :class="{ 'expand': isExpandCertificate, 'collapse': !isExpandCertificate }">
          <li>
            <input type="radio" name="cert" class="input-hidden" id="all" v-model="certificate" :value="null"
              @click="saveCertificate(null)">
            <label for="all" class="label-radio">Все партнеры</label>
          </li>
          <li>
            <input type="radio" name="cert" class="input-hidden" id="has-cert" v-model="certificate" :value="true"
              @click="saveCertificate(true)">
            <label for="has-cert" class="label-radio">С сертификатом</label>
          </li>
          <li>
            <input type="radio" name="cert" class="input-hidden" id="no-cert" v-model="certificate" :value="false"
              @click="saveCertificate(false)">
            <label for="no-cert" class="label-radio">Без сертификата</label>
          </li>
        </ul>
      </div>
    </div>
    <div class="filters__footer">
      <button class="filters__btn filters__btn--apply btn btn-green btn-small" type="submit">
        Применить
        <img src="@/assets/images/svg/chevron-right_white.svg" alt="">
      </button>
      <button class="filters__btn" @click.prevent="clearAll">
        Сбросить фильтры
      </button>
    </div>
  </form>
</template>

<script>
import DirectionColors from '@/helpers/DirectionColors';
import partnerFilter from '@/helpers/PartnerFiltes';
import EncodeDecodeFilter from '@/helpers/EncodeDecodeFilter';

export default {
  name: 'PartnerFilters',
  props: {
    map: Object
  },
  data() {
    return {
      list: [],
      certificate: null,
      isExpandCertificate: true,
      attrs: false
    }
  },
  created() {
    this.getPartnerAttributes();
    this.getPartnerTypes();
    this.getAvailableProducts();
  },
  mounted() {
    this.certificate = this.filterList.certificate
    this.attrs = this.filterList.attr
    for (const listItem of this.list) {
      for (const filter of this.filterList.filters) {
        if (listItem.name === filter.group) {
          listItem.options = listItem.options.map(e => (filter.names.includes(e.name) ? { ...e, selected: true } : e))
        }
      }
    }
  },
  watch: {
    availables() {
      if (this.activeDirectionIds.length) {
        this.getPartnerTypes();
        this.getPartnerAttributes();
        this.getAvailableProducts();
      }
    }
  },
  methods: {
    saveCertificate(val) {
      this.$store.dispatch('setPartnerCertificate', val)
    },
    toggleExpand(index, onlyCollapse = false) {
      if (onlyCollapse) {
        if (this.list[index].collapse !== null) {
          this.list[index].collapse = !this.list[index].collapse;
        } else {
          this.list[index].collapse = true;
        }
      }
      if (this.list[index].expand === undefined || (onlyCollapse && !this.list[index].expand)) {
        return;
      }
      this.list[index].expand = !this.list[index].expand;
    },
    checkboxHandler(option, groupName, groupId) {
      if (groupName === 'Признак партнера') {
        this.attrs = true
      }
      this.$store.dispatch('setPartnerFilter',
        {
          name: option.name,
          groupName,
          groupId: groupName !== 'Тип партнера' ? groupId : groupId,
          certificate: this.certificate,
          attr: this.attrs
        })
    },
    clearFilter(index, groupName = '') {
      this.$store.dispatch('clearPartnerFilter', groupName)
      if (groupName === 'Признак партнера') {
        this.$store.dispatch('setPartnerAttributes', false)
        this.attrs = false
      }
      if (this.list[index].options) {
        this.list[index].options.forEach(option => {
          option.selected = false;
        });
      } else if (this.list[index].type === 'range') {
        const group = this.list[index];
        group.touched = false;
        group.value = [group.range.start, group.range.end];
      }
    },
    clearAll() {
      this.$store.dispatch('clearPartnerFilter')
      this.certificate = null
      this.attrs = false
      this.list.forEach((_, index) => {
        this.clearFilter(index);
      });
    },
    getPartnerAttributes() {
      const attributes = this.$store.getters['getPartnerAttributes'].filter(attr => this.activeDirectionIds.includes(attr.direction))
      const findedItem = this.list.find(el => el.name === 'Признак партнера')
      const options = attributes.map(attr => ({ ...attr, selected: false }))
      if (attributes.length) {
        if (findedItem) {
          findedItem.options = options
        } else {
          const hasType = this.list.find(el => el.name === 'Тип партнера')
          this.list = hasType ? [hasType, {
            type: 'checkbox',
            name: 'Признак партнера',
            options,
            expand: false,
            collapse: null
          }, ...this.list] : [{
            type: 'checkbox',
            name: 'Признак партнера',
            options,
            expand: false,
            collapse: null
          }, ...this.list]
        }
      }
    },
    getPartnerTypes() {
      const types = this.$store.getters['getPartnerTypes'].filter(type => this.activeDirectionIds.includes(type.direction));
      const findedItem = this.list.find(el => el.name === 'Тип партнера')
      const options = types.map(type => {
        const code = this.directions.find(dir => dir.id === type.direction).code;
        return {
          ...type,
          code,
          selected: false
        }
      })

      if (findedItem) {
        findedItem.options = options
      } else {
        this.list = [{
          type: 'checkbox',
          name: 'Тип партнера',
          options,
          expand: false,
          collapse: null,
          icons: true
        }, ...this.list]
      }
    },
    getAvailableProducts() {
      const groups = this.$store.getters['getGroups'];
      const filteredGroup = groups.filter(g => this.activeDirectionIds.includes(g.direction)).map(group => ({
        id: group.id,
        name: group.name,
        options: this.$store.getters['getProductsByGroup'](group.id).map(product => ({
          ...product,
          selected: false
        })),
        type: 'checkbox',
        expand: false,
        collapse: null,
        icons: false,
        isRolledUp: false
      }))
      this.list = [...this.list.filter(listItem => !listItem.id), ...filteredGroup]
    },
    applyFilters() {
      const encode = EncodeDecodeFilter.encode(this.activeDirectionIds, this.filterList.filters, []);
      const directionIDs = encode.directionsId;
      const filterIDs = encode.filtersId;
      const attributes = this.filterList.attr;
      const certificate = this.filterList.certificate;

      if (filterIDs && certificate !== null) {
        this.$router.push(`/partners/results/${directionIDs}/${filterIDs}/${attributes}/${certificate}`);
      } else if (filterIDs) {
        this.$router.push(`/partners/results/${directionIDs}/${filterIDs}/${attributes}`);
      } else if (certificate !== null) {
        this.$router.push(`/partners/results/${directionIDs}/${attributes}/${certificate}`);
      } else {
        this.$router.push(`/partners/results/${directionIDs}/${attributes}`);
      }
    },
    computeDirectionColor(code) {
      if (code) {
        return DirectionColors[code.toLowerCase()];
      }
      return '';
    }
  },
  computed: {
    hasSelectedItems() {
      return this.list.map(group => {
        if (group.options && group.options.length) {
          return !!group.options.filter(option => option.selected).length;
        }
        return null;
      });
    },
    filterList() {
      return this.$store.getters['getPartnerFilter']
    },
    directions() {
      return this.$store.getters['getDirections'];
    },
    activeDirectionIds() {
      return this.directions.filter(dir => this.activeDirections.includes(dir.code)).map(dir => dir.id);
    },
    activeDirections() {
      return this.$store.getters['getActiveDirections'];
    },
    isAuth() {
      return this.$store.getters['isAuth'];
    },
    availables() {
      return this.$store.getters['getAvailables']
    },
    savedCertificate() {
      return this.$store.getters['getPartnerFilterCertificate']
    }
  }
}
</script>

<style scoped>
.filters {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  overflow-y: hidden;
}

.filters__inner {
  overflow-y: auto;
  margin-right: 5px;
}

.filters__inner>.filters__group:first-child {
  margin-top: 10px;
}

.filters__inner::-webkit-scrollbar {
  width: 5px;
}

.filters__inner::-webkit-scrollbar-thumb {
  background-color: black;
}

.filters__footer {
  margin-top: auto;
  padding: 20px 15px;
  border-top: 1px solid #BFBFBF;
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: #fff;
}

.filters__group {
  padding: 0 10px;
  margin-bottom: 20px;
}

.filters__group-name {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
}

.filters__chevron {
  display: inline-flex;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: background-color .5s ease;
}

.filters__chevron.active {
  background-color: #58BCAF;
}

.filters__chevron img {
  width: 12px;
  height: 8px;
  transform: rotate(0deg);
  transition: transform .3s ease;
}

.filters__chevron.collapse img {
  transform: rotate(-90deg);
}

.filters__options {
  margin-top: 10px;
}

.filters__options li:nth-child(n+5) {
  display: none;
}

.filters__options.expand li:nth-child(n+5) {
  display: block;
}

.filters__options.collapse {
  display: none;
}

.input-hidden {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.label-checkbox,
.label-radio {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 5px 0 5px 29px;
  font-size: 18px;
  line-height: 20px;
  background-color: #fff;
  transition: background-color .3s ease;
  cursor: pointer;
}

.label-checkbox:hover,
.label-radio:hover {
  background-color: #E5E5E5;
}

.label-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 19px;
  height: 19px;
  border: 1px solid #000000;
  background-color: #fff;
}

.label-checkbox::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  width: 12px;
  height: 12px;
  background: url("../assets/images/svg/checked.svg") no-repeat;
  opacity: 0;
  z-index: 2;
}

.label-radio::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 19px;
  height: 19px;
  border: 1px solid #000000;
  border-radius: 50%;
  background-color: #fff;
}

input:checked+.label-checkbox::before,
input:checked+.label-radio::before {
  background-color: #000;
}

input:checked+.label-checkbox::after {
  opacity: 1;
}

.filters__btn {
  font-size: 18px;
  line-height: 24px;
  color: #DC143C;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filters__btn:not(:last-child) {
  margin-right: 20px;
}

.filters__btn--apply {
  color: #fff;
}

.filters__btn img {
  width: 8px;
  height: 12px;
  margin-left: 8px;
}

.type-icon {
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  margin-right: 10px;
  border: 4px solid currentColor;
  border-radius: 50%;
  background-color: #fff;
}

.type-icon svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

@media (max-width: 1023px) {
  .filters {
    height: calc(100vh - 180px);
  }
}
</style>
